// This is an attempt to prevent extremely unfortunate caching issues
// we've experienced during some recent deploys... for which the only
// fix we could find was having people clear their caches. YUCK.

// https://code.angularjs.org/1.0.7/docs/api/ng.$route#Example

//angular.module('service_order_wizard', [], function($routeProvider)
app.config(['$routeProvider', function($routeProvider) {
  $routeProvider.
    when('/assignments/grid',
    {
      templateUrl: "/angular_templates/assignments/grid.html",
      controller: 'GridCtrl'
    }).
    when('/customers/:customer_id/service_orders/new',
    {
      templateUrl: "/angular_templates/service-orders/new.html",
      controller: 'NewServiceOrderCtrl'
    }).
    when('/locations/:location_id',
    {
      templateUrl: "/angular_templates/locations/show.html",
      controller: 'LocationsCtrl'
    }).
    when('/service_orders/:service_order_id/edit',
    {
      templateUrl: "/angular_templates/service-orders/new.html",
      controller: 'EditServiceOrderCtrl'
    }).
    when('/service_orders/:service_order_id/items',
    {
      templateUrl: "/angular_templates/furniture/list.html",
      controller: 'ServiceOrderItemsCtrl'
    }).
    when('/customers/:customer_id/pre_qs/new',
    {
      templateUrl: "/angular_templates/pre_qs/new.html",
      controller: 'NewPreQCtrl'
    }).
    when('/pre_qs/:pre_q_id/items',
    {
      templateUrl: "/angular_templates/pre_q_items/list.html",
      controller: 'PreQItemsCtrl'
    }).
    when('/customers/:customer_id/viz_techs/new',
    {
      templateUrl: "/angular_templates/viz_techs/new.html",
      controller: 'NewVizTechCtrl'
    }).
    when('/viz_techs/:viz_tech_id/items',
    {
      templateUrl: "/angular_templates/viz_tech_items/list.html",
      controller: 'VizTechItemsCtrl'
    }).
    when('/routes/new',
    {
      templateUrl: "/angular_templates/routes/form.html",
      controller: 'NewRouteCtrl'
    }).
    when('/routes/:route_id/edit',
    {
      templateUrl: "/angular_templates/routes/form.html",
      controller: 'EditRouteCtrl'
    }).
    when('/routes/:route_id/map',
    {
      templateUrl: "/angular_templates/routes/map.html",
      controller: 'RouteMapCtrl',
      reloadOnSearch: false
    }).
    when('/routes/:route_id/waypoints',
    {
      templateUrl: "/angular_templates/routes/waypoints.html",
      controller: 'RouteWaypointsCtrl'
    }).
    when('/routes/manage',
    {
      templateUrl: "/angular_templates/routes/manager.html",
      controller: 'RouteManagerCtrl'
    }).
    when('/routes/manage-dual-screen',
    {
      templateUrl: "/angular_templates/routes/manager-dual-screen.html",
      controller: 'RouteManagerCtrl'
    }).
    when('/routes/plan',
    {
      templateUrl: "/angular_templates/routes/planner.html",
      controller: 'PlanRouteCtrl',
      reloadOnSearch: false
    }).
    when('/service_orders/:service_order_id/follow-up',
    {
      templateUrl: "/angular_templates/service-orders/follow-up.html",
      controller: 'FollowUpServiceOrdersCtrl'
    }).
    when('/appointments/:appointment_id/tech_report',
    {
      templateUrl: "/angular_templates/tech_reports/edit.html",
      controller: 'TechReportCtrl'
    }).
    when('/appointments/:appointment_id/tech_report/:item_id/:sub_report',
    {
      templateUrl: "/angular_templates/tech_reports/sub-report.html",
      controller: 'SubReportCtrl'
    }).
    when('/bills',
    {
      templateUrl: "/angular_templates/bills/overview.html",
      controller: 'BillingOverviewCtrl'
    }).
    when('/clients/:client_id/analytics',
    {
      templateUrl: "/angular_templates/clients/analytics.html",
      controller: 'ClientAnalyticsCtrl'
    }).
    when('/clients/:client_id/:company/bills',
    {
      templateUrl: "/angular_templates/clients/bills.html",
      controller: 'BillsCtrl'
    }).
    when('/clients/:client_id/billable_pre_qs',
    {
      templateUrl: "/angular_templates/clients/billable_pre_qs.html",
      controller: 'BillablePreQsCtrl'
    }).
    when('/clients/:client_id/billable_viz_techs',
    {
      templateUrl: "/angular_templates/clients/billable_viz_techs.html",
      controller: 'BillableVizTechsCtrl'
    }).
    when('/variances',
    {
      templateUrl: "/angular_templates/variances.html",
      controller: 'VariancesCtrl'
    }).
    otherwise(
    {
      templateUrl: "/angular_templates/404.html"
    })

}]);

